import React from 'react';
import LineFull from '../../images/linefull.jpg';

const PosterPurposeDownload = purposeData => {
    const data = purposeData.posterData;
    return (
        <section className="page-section smallestwdt centersec nopaddtopsec">
            <div className="container purposeposter">
                <img className="purposeline" src={LineFull} alt="line full" />
                <h2
                    className="bluetxt"
                    dangerouslySetInnerHTML={{
                        __html: data.purpose_poster_title,
                    }}
                />
                <p
                    className="biggertxt"
                    dangerouslySetInnerHTML={{
                        __html: data.purpose_poster_description,
                    }}
                />

                <div className="threesessions">
                    <h5>Download PLAY THE PURPOSE&trade; Poster</h5>
                </div>
                <img
                    src={
                        data.purpose_poster_image.localFile.childImageSharp
                            .fluid.src
                    }
                    alt="playposter"
                />
                <a
                    href={
                        data.purpose_poster_image.localFile.childImageSharp
                            .fluid.src
                    }
                    rel="noopener noreferrer"
                    className="wrtsbtn yellowbtn"
                >
                    DOWNLOAD
                </a>
            </div>
        </section>
    );
};
export default PosterPurposeDownload;
